import React from 'react'
import { PageProps } from 'gatsby'
import { useApi } from '~/siteApi'
import { Head, Banner, DefaultContainer, Footer } from '~/components'

interface PageContext {
  department: {
    id: number
    name: string
    slug: string
    slogan: string
    resume: string
    ctaMore: string
    ctaDeliver: string
    deliverTitle: string
    deliverContent: string
    deliverCta: string
    image: string
    mobile: string
  }
}

const Departments = ({
  location,
  pageContext = {},
  '*': slug,
}: PageProps & { '*'?: string }) => {
  const context = useApi(
    pageContext,
    `page-department/${(pageContext as PageContext).department?.slug || slug}`
  ) as PageContext

  const {
    name,
    slogan,
    resume,
    ctaMore,
    ctaDeliver,
    deliverTitle,
    deliverContent,
    deliverCta,
    image,
    mobile,
  } = context.department || {}

  return context.department ? (
    <>
      <Head location={location} />
      <main>
        <Banner
          title={name}
          subtitle={slogan}
          text={resume}
          img={image}
          imgMobile={mobile}
          ctaMore={ctaMore}
          ctaDeliver={ctaDeliver}
        />
        <DefaultContainer
          title={deliverTitle}
          text={deliverContent}
          deliverCta={deliverCta}
          slug={context.department.slug}
        />
      </main>
      <Footer location={location} />
    </>
  ) : (
    <></>
  )
}

export default Departments
